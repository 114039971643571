
import { CodeEditor } from '@acrodata/code-editor';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { autocompletion, CompletionContext } from "@codemirror/autocomplete";
import { javascriptLanguage } from "@codemirror/lang-javascript";
import { languages } from '@codemirror/language-data';
import { EditorView } from "@codemirror/view";
import { RouteShareService } from 'src/app/shared/services/route-share.service';
export type smartCommentSingleType = "block" | "line";
export type smartCommentType = `${smartCommentSingleType}-${smartCommentSingleType}-${smartCommentSingleType}` | `${smartCommentSingleType}-${smartCommentSingleType}` | `${smartCommentSingleType}`;
@Component({
  selector: 'app-code-editor-dann',
  templateUrl: './code-editor-dann.component.html',
  standalone: true,
  imports: [CommonModule, CodeEditor, FormsModule],

})
export class CodeEditorDannComponent implements OnInit {
  @Input() public variables: string[] = [];
  @Input() public skills: string[] = [];
  @Input() public title: string = '';
  @Input() public code: string = '';
  @Input() public rows: number = 5;
  @Output() public finish: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("codeEditor") public editor!: CodeEditor;
  protected languages = languages;
  protected autocompletion = autocompletion;
  protected javascriptLanguage = javascriptLanguage;
  public extensions = [];

  constructor(public rss: RouteShareService) {
    //@ts-ignore
    this.extensions.push(javascriptLanguage.data.of({ autocomplete: this.myCompletions.bind(this) }));
  }

  public ngOnInit(): void {
    //Erste Codezeile ist immer 30,39px hoch (Zeile -> 22,3906px + Space -> 8px)
    //Jede weitere Zeile fügt 22,3906px hinzu
    const heightToRows = 8 + this.rows * 22.3906;
    //@ts-ignore
    this.extensions.push(EditorView.theme({
      ".cm-gutters": {
        backgroundColor: "#00A599",
        color: "#fff"
      },
      ".cm-activeLineGutter": {
        backgroundColor: "#99DBD6"
      },
      ".cm-content": {
        minHeight: heightToRows + "px"
      },
      ".cm-gutter": {
        minHeight: heightToRows + "px"
      }
    }));
  }

  public saveToolConfig() {
    this.finish.emit(this.code);
  }

  public getConfEntrys() {
    const ret = [];
    ret.push({ label: "conf.botName", info: "Name des Bots" });
    ret.push({ label: "conf.botName", info: "Farbkonfiguration" });
    return ret;
  }

  public getSkillEntrys() {
    const ret = [];
    for (const skill of this.skills) {
      ret.push({ label: "'" + skill + "'", type: "text", info: "Skill-Name: '" + skill + "'" });
      ret.push({ label: "skill." + skill + "_count", type: "variable", info: "Skill-Ausführungszahl '" + skill + "'" });
      ret.push({ label: "skill." + skill, type: "variable", info: "Skill Status '" + skill + "'" });
      ret.push({ label: skill, type: "keyword", detail: "(Aktivieren)", apply: "RAGAI.enableSkills(['" + skill + "']);" });
      ret.push({ label: skill, type: "keyword", detail: "(Deaktiveren)", apply: "RAGAI.disableSkills(['" + skill + "']);" });
      ret.push({ label: skill, type: "text", detail: "(Überprüfen)", info: "Skill-Überprüfen ob aktiv '" + skill + "'", apply: "if(skill." + skill + "){\n\n}" });
    }
    return ret;
  }

  public getVariableEnrys() {
    const ret = [];
    for (const variable of this.variables) {
      ret.push({ label: "🧷 vars." + variable + "", type: "", info: variable, apply: "vars." + variable + "" });
    }
    return ret;
  }

  /* public getCompleteOption(code: string, info: string, apply?: string) {
    return { label: code, type: "", info: info, apply: apply ? apply : code + ";" };
  } */
  public getCompleteOptionHumanReadable(code: string, info: string, apply?: string) {
    return { label: '📦 [' + info + ']', type: "", info: info, apply: apply ? apply : code };
  }

  public getCompleteOptionLine(code: string, info: string) {
    const apply = `${code} // ${info}`;
    return { label: '📏 ' + info + ' //... ', type: "", info: code, apply: apply };

  }

  public getCompleteCoderFriendly(code: string, initialInfo: string) {
    let completion = code;
    let info = initialInfo;
    if (code.startsWith("await ")) {
      completion = code.split("await ").join("");
      info = "(await) " + initialInfo;
    }

    if (code.startsWith("var")) {
      //@ts-ignore
      completion = code.split("=")[1];
      completion = completion.trim();
      info = "(var) " + initialInfo;
    }

    return {
      label: '🤖' + completion, type: "", info: initialInfo, apply: code
    };
  }


  /* public getCompleteOptionAwait(code: string, info: string) {
    const apply = `${code}; // ${info}`;
    return { label: code.split("await ").join("") + ' //... ', type: "variable", info: "ist ein await", apply: apply };
  } */

  public getCompleteOptionBLOCK(code: string, info: string) {
    const apply = `/************************************ \n ${info} \n ************************************/\n` + code + ";\n";
    return { label: '📦[' + info + ']', type: "", info: "(Quellcode-Template)", apply: apply };

  }


  public getCompleteOptionSmart(code: string, info: string, blockType: smartCommentType = "line") {
    if (code.endsWith(')')) {
      code = code + ";";
    }
    const ret = [];
    // FÜR DIE COOLEN KIDS
    ret.push(this.getCompleteCoderFriendly(code, info));


    if (code.includes("\n")) {
      ret.push(this.getCompleteOptionBLOCK(code, info));

    } else {
      ret.push(this.getCompleteOptionLine(code, info));
    }
    /* // BLÖCKE FÜR DIE NORMALEN MENSCHEN
    if (blockType.includes("block")) {
      ret.push(this.getCompleteOptionBLOCK(code, info));
    }
    if (blockType.includes("line")) {
      ret.push(this.getCompleteOptionLine(code, info));
    }
 */
    //
    /* if (blockType.includes("await")) {
      ret.push(this.getCompleteOptionAwait(code, info));
    }
 */
    return ret;


  }


  public myCompletions(context: CompletionContext) {
    const word = context.matchBefore(/[\w.]*$/);
    if (word?.from == word?.to && !context.explicit)
      return null;
    return {
      from: word?.from,
      options: [
        ...this.getConfEntrys(),
        ...this.getSkillEntrys(),
        ...this.getVariableEnrys(),
        ...this.getCompleteOptionSmart("RAGAI.DOM.body().style", "", "line"),

        ...this.getCompleteOptionSmart("RAGAI.clearTokensAndReload()", "Logged den Nutzer aus!!! Und Läd dann neu.", "line"),


        ...this.getCompleteOptionSmart("RAGAI.DOM.header().style", "", "line"),
        ...this.getCompleteOptionSmart("RAGAI.DOM.content().style", "", "line"),
        ...this.getCompleteOptionSmart("RAGAI.DOM.footer().style", "", "line"),
        ...this.getCompleteOptionSmart("RAGAI.showOverlay('Ich bin ein Text')", "Overlay mit Text anzeigen", "block-line"),
        // KOMFETTI
        ...this.getCompleteOptionSmart("RAGAI.confetti()", "Konfetti Animation", "block-line"),
        // DOWNLOAD
        ...this.getCompleteOptionSmart("RAGAI.downloadTextAsFile('Text','dateiname.txt')", "Textdatei herunterladen", "block-line"),
        ...this.getCompleteOptionSmart(`RAGAI.CHARTS.radar([1,2,3,4,5],["Dimension 1","Dimension 2","Dimension 3","Dimension 4","Dimension 5"]);`, "Radar Chart", "block-line"),
        // OVERLAY MIT BUTTONS
        ...this.getCompleteOptionSmart(`RAGAI.showOverlayWithButtons(
         [
           {text:'ja',className:'btn btn-primary',onClick:function(){}},
           {text:'nein',className:'btn btn-primary',onClick:function(){}},
         ]
       )`, "Overlay mit Knöpfen anzeigen", "block"),

        ...this.getCompleteOptionSmart("RAGAI.disableSkills([conf.skillId])", "Aktueller skill deaktivieren", "line"),
        ...this.getCompleteOptionSmart("RAGAI.enableSiklls([conf.skillId])", "Aktueller skill aktivieren", "line"),
        ...this.getCompleteOptionSmart("RAGAI.SCORM.LMSSetValue('cmi.core.lesson_status', 'completed')", "SCORM Status completed setzen", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.SCORM.LMSSetValue('cmi.core.lesson_status', 'passed')", "SCORM Status passed setzen", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.SCORM.LMSSetValue('cmi.core.lesson_status', 'failed')", "SCORM Status failed setzen", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.SCORM.LMSSetValue('cmi.core.suspend_data', dataString)", "SCORM Suspended Data speichern", "block-line"),

        ...this.getCompleteOptionSmart(`var externalCustomReturnVal = await RAGAI.callExternalFunction("myRegisteredFunctionName", "param1", "param2");
/* Um eine Funktion die ausserhalb des Bots liegt über die BotApi aufrufbar zu machen: 
  const meineBotApi = await botApi("meinbot1", true);
  meinbot.registerExternalFunction("myRegisteredFunctionName", async (p1, p2) => {
    return { "myCustomReturnValue": { p1, p2 } }
  })
*/`, "Externe Funktion ausführen", "block"),


        ...this.getCompleteOptionSmart(`const externalFunctionNames = await RAGAI.getRegisteredFunctionNames();
console.log(externalFunctionNames);`, "Externe Funktionsnamen auslesen", "block-line"),

        ...this.getCompleteOptionSmart("var achieve = RAGAI.score.getAchieveMents()", "SCORE Achievements als JSON holen", "block-line"),
        ...this.getCompleteOptionSmart("var achieve = RAGAI.score.getAchieveMents(false)", "SCORE Achievements als Text holen", "block-line"),
        ...this.getCompleteOptionSmart(`var myInactiveHook = RAGAI.EVENTS.inActiveHook(120);
      myInactiveHook.action = function (count, totalcount) {
        console.log('Seit letzter aktivität ' + count + 'aktivierungen. ' + totalcount + ' Aktivierungen insgesamt'); // Hier die gewünsche aktion
      }
                 //myInactiveHook.destroy(); // den Timer zerstören,
                 //myInactiveHook.reset(); // Wenn gewünscht so den Timer zurücksetzen`, "Aktion nach X Sekunden Inaktivität ausführen", "block"),
        ...this.getCompleteOptionSmart("await RAGAI.WAITFOR.varsInit();", "Wartet darauf das der Score und der Keyvalstore geladen wurde."),
        ...this.getCompleteOptionSmart("await RAGAI.WAITFOR.seconds(X);", "Wartet X Sekunden"),
        ...this.getCompleteOptionSmart("await RAGAI.WAITFOR.minutes(X);", "Wartet X Minuten"),
        ...this.getCompleteOptionSmart("RAGAI.score.db('DB_NAME').get();", "Gibt den aktuellen Score zurück.Für die Datenbank 'dbName'"),
        ...this.getCompleteOptionSmart("RAGAI.score.db('DB_NAME').getAchieveMents();", "gibt die liste der Erfolge. Für die Datenbank 'dbName' (asJson: boolean = false)"),
        ...this.getCompleteOptionSmart("RAGAI.score.db('DB_NAME').reset();", "setzt den aktuellen Score zurück. Für die Datenbank 'dbName'"),
        ...this.getCompleteOptionSmart("RAGAI.score.db('DB_NAME').set();", "Setzt den aktuellen Score. Für die Datenbank 'dbName' (punkte: number)"),
        ...this.getCompleteOptionSmart("RAGAI.score.db('DB_NAME').sub();", "Verringert den aktuellen Score. Für die Datenbank 'dbName' (punkte: number)"),
        ...this.getCompleteOptionSmart("RAGAI.score.db('DB_NAME').achieve();", "Archiviert einen Erfolg. Für die Datenbank 'dbName' (value: number, skillId: string, message: string, autoMessage: boolean = false)"),
        ...this.getCompleteOptionSmart("RAGAI.KEYVAL.get('YOUR_KEY')", "Gibt den Wert für den übergebenen Key zurück ", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.KEYVAL.set('YOUR_KEY', 'YOUR_VALUE');", "Setzt den Wert für den übergebenen Key, Objekte sind als Wert erlaubt", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.KEYVAL.set('YOUR_KEY', 'YOUR_VALUE', 'YOUR_TYPE')", "Setzt den Wert für den übergebenen Key mit einem Typ über welchen alle Werte für diesen Typ geholt werden können. Objekte sind als Wert erlaubt", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.KEYVAL.getByType('YOUR_KEY', 'YOUR_VALUE', 'YOUR_TYPE')", "Holt alle KeyVal Objekte für den übergebenen Typ", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.score.get();", "Gibt den aktuellen Score zurück"),
        ...this.getCompleteOptionSmart("RAGAI.score.reset();", "setzt den aktuellen Score zurück"),
        ...this.getCompleteOptionSmart("RAGAI.score.set(1);", "Setzt den aktuellen Score (punkte: number)"),
        ...this.getCompleteOptionSmart("RAGAI.score.sub(1);", "Verringert den aktuellen Score (punkte: number)"),
        ...this.getCompleteOptionSmart("RAGAI.score.achieve();", "Archiviert einen Erfolg. (value: number, skillId: string, message: string, autoMessage: boolean = false)"),
        ...this.getCompleteOptionSmart("RAGAI.SCORM.LMSSetValue('cmi.core.lesson_status', 'STATUS')", "completed oder passed oder failed"),
        ...this.getCompleteOptionSmart("RAGAI.SCORM.LMSSetValue('cmi.core.suspend_data', dataString)", "Speichert Daten im LMS"),
        ...this.getCompleteOptionSmart("RAGAI.SCORM.scormHook = function(hook, payload){ console.log('Empfange ' + hook, payload) }", "Empfängt Nachrichten vom LMS"),
        ...this.getCompleteOptionSmart("RAGAI.toastr.success('YOUR_MESSAGE_HERE');", "Zeigt eine Erfolgsmeldung an (text: string)"),
        ...this.getCompleteOptionSmart("RAGAI.toastr.error('YOUR_MESSAGE_HERE');", "Zeigt eine Fehlermeldung an (text: string)"),
        ...this.getCompleteOptionSmart("RAGAI.toastr.info('YOUR_MESSAGE_HERE');", "Zeigt eine Info an (text: string)"),
        ...this.getCompleteOptionSmart("RAGAI.cancel();", "Bricht den aktuellen Dialog ab"),
        ...this.getCompleteOptionSmart("RAGAI.iframeCard('https://','YOUR_STYLE_CLASS');", "Fügt einen Iframe ein (url: string)"),


        ...this.getCompleteOptionSmart(`RAGAI.initialMessages = async function () {
          return [{
            role: "user",
            content: "Mein Name ist Max Mustermann",
            bypass: true
          }];
        }`, "Initiale Nachricht hinzufügen"),

        ...this.getCompleteOptionSmart(`RAGAI.initialMessages = async function () {
          return [{
            role: "user",
            content: "Mein Name ist " + (await RAGAI.SCORM.LMSGetValue("cmi.core.student_name")),
            bypass: true
          }];
        }`, "LMS Nutzeramen beim Start übertragen"),

        ...this.getCompleteOptionSmart("RAGAI.userMessage('YOUR_MESSAGE');", "Simuliert die Eingabe des Users (message: string)"),
        ...this.getCompleteOptionSmart("RAGAI.userBypass('YOUR_MESSAGE');", "sende eine verstecke Nachricht ohne Dialog (message: string)"),
        ...this.getCompleteOptionSmart("RAGAI.botBypass('YOUR_MESSAGE');", "sende eine verstecke Nachricht ohne Dialog (message: string)"),
        ...this.getCompleteOptionSmart("RAGAI.botMessage('YOUR_MESSAGE');", "Simuliert die Textausgabe des Bots (message: string)"),
        ...this.getCompleteOptionSmart("RAGAI.botSay('YOUR_MESSAGE');", "Sprachausgabe des Bots (message: string)", "block-line"),
        ...this.getCompleteOptionSmart("RAGAI.runMessage('YOUR_MESSAGE')", "Handlungsanweiung für den Bot (message: string)"),
        ...this.getCompleteOptionSmart("RAGAI.youtubeCard('YOUR_YOUTUBE_VIDEO_ID')", "Fügt ein Youtube Video ein (videoId: string)"),
        ...this.getCompleteOptionSmart("RAGAI.vimeoCard('YOUR_VIMEO_VIDEO_ID','YOUR_VIMEO_VIDEO_HASH')", "Fügt ein Vimeo Video ein (videoId: string, hash-token: string)"),
        ...this.getCompleteOptionSmart("RAGAI.imageCard('YOUR_IMAGE_LINK')", "Fügt ein Bild ein (scr: string, altText: string, cssClass: string)"),
        ...this.getCompleteOptionSmart("RAGAI.resetBot()", "Setzt den Bot zurück"),
        ...this.getCompleteOptionSmart("RAGAI.getCurrentDialog()", ""),
        ...this.getCompleteOptionSmart("RAGAI.addDialogMessages()", ""),
        ...this.getCompleteOptionSmart("RAGAI.storeDialogToLocalStorage()", ""),
        ...this.getCompleteOptionSmart("RAGAI.appendDialogFromLocalStorage()", ""),
        ...this.getCompleteOptionSmart("RAGAI.appendDialogFromLocalStorageIgnoreExisting()", ""),
        ...this.getCompleteOptionSmart("RAGAI.enableSkills([YOUR_SKILL_ID])", "Aktiviert die übergebenen Skills"),
        ...this.getCompleteOptionSmart("RAGAI.disableSkills([YOUR_SKILL_ID])", "Deaktiviert die übergebenen Skills"),

        ...this.getCompleteOptionSmart("RAGAI.enableSkillAndChildren(YOUR_SKILL_ID, 2)", "Aktiviert den Skill und alle Kinder bis zur Tiefe 2"),
        ...this.getCompleteOptionSmart("RAGAI.disableSkillAndChildren(YOUR_SKILL_ID, 2)", "Deaktiviert den Skill und alle Kinder bis zur Tiefe 2"),


        ...this.getCompleteOptionSmart("RAGAI.endBot({})", "Beendet den Bot"),
        ...this.getCompleteOptionSmart("RAGAI.endBot({title:'Der Bot wurde Beendet',message:'Aus folgendem Grund' })", "Beendet den Bot"),



      ]
    };
  }

  public insertInEditor(varName: string): void {
    if (this.editor.view) {
      this.code = this.code.slice(0, this.editor.view.state.selection.main.head) + varName + this.code.slice(this.editor.view.state.selection.main.to);
    } else {
      this.code = this.code + `${varName}\n`;
    }
    this.saveToolConfig();
  }
}
