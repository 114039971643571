import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatbotConfig } from '@reflact/ai-types';
import dayjs from 'dayjs';
import { LoginService } from 'src/app/shared/services/login.service';

const sortModes = ["asc-alphabet", "desc-updated", "desc-created"] as const;
export type SortMode = typeof sortModes[number];

@Component({
  selector: 'app-bot-admin-query-search',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './bot-admin-query-search.component.html',
  styleUrl: './bot-admin-query-search.component.scss'
})
export class BotAdminQuerySearchComponent implements OnChanges {

  @Input() public search: string = "";
  @Input() public sortMode: SortMode = "desc-created";
  @Input() public items: ChatbotConfig[] = [];
  @Output() public searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public searchResultChange: EventEmitter<ChatbotConfig[]> = new EventEmitter<ChatbotConfig[]>();

  public splittedSearchBlocks: { filter?: string, value: string }[] = [];
  public searchResult: ChatbotConfig[] = [];

  constructor(private loginservice: LoginService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["items"] || changes["search"]) {
      this.onSearchChange();
    }
    if (changes["sortMode"]) {
      this.searchResult = this.sortBots(this.searchResult);
      this.searchResultChange.emit(this.searchResult);
    }
  }

  public onSearchChange(): void {
    this.searchChange.emit(this.search);
    this.getSplittedSearchBlocks();
    this.searchResult = this.items.filter((item: ChatbotConfig) => {
      return this.splittedSearchBlocks.every((block) => {
        if (block.filter) {
          switch (block.filter) {
            case "id":
              return item._id.toLowerCase().includes(block.value.toLowerCase());
            case "name":
              return item.name.toLowerCase().includes(block.value.toLowerCase());
            case "tag":
              return item.tagList.some((tag) => `'${tag.toLowerCase()}'`.includes(block.value.toLowerCase()));
            case "trashed":
              return item.thisIsTrash === (block.value === "true") && item.creator === this.loginservice.loggedInUser?._id;
            case "archived":
              return item.archived === (block.value === "true") && item.creator === this.loginservice.loggedInUser?._id;
            case "org":
              return !item.thisIsTrash && !item.archived && item.orgShort.includes(block.value.toLowerCase());
            default:
              // Default: no archived and no trashed
              if (this.splittedSearchBlocks.every(sb => sb.filter !== "thisIsTrash" && sb.filter !== "archived")) {
                return !item.thisIsTrash && !item.archived && item.creator === this.loginservice.loggedInUser?._id;
              }
              return false;
          }
        } else {
          // Default: no archived and no trashed
          if (this.splittedSearchBlocks.every(sb => sb.filter !== "trashed" && sb.filter !== "archived" && sb.filter !== "tag" && sb.filter !== "org")) {
            return item.name.toLowerCase().includes(block.value.toLowerCase()) && !item.thisIsTrash && !item.archived && item.creator === this.loginservice.loggedInUser?._id;
          }
          return item.name.toLowerCase().includes(block.value.toLowerCase());
        }
      });
    });
    this.searchResult = this.sortBots(this.searchResult);
    this.searchResultChange.emit(this.searchResult);
  }

  public getSplittedSearchBlocks(): void {
    const arr: string[] = this.search.split("'"); // alle ungeraden Elemente sind in Anführungszeichen
    const searchBlocks: string[] = [];
    if (arr.length > 1 && arr[arr.length - 1] === "") arr.pop();
    arr.forEach((item, i) => {
      if (i % 2 === 0) {
        searchBlocks.push(...item.split(" "));
      } else {
        searchBlocks[searchBlocks.length - 1] += ("'" + item + "'");
      }
    });
    const res = searchBlocks.map((block) => {
      if (!block.includes(":")) {
        return { value: block };
      } else {
        const filterName = block.split(":")[0];
        const value = block.split(":")[1];
        switch (filterName) {
          case "archived":
          case "trashed":
          case "id":
          case "name":
          case "org":
          case "tag":
            if (value) {
              return { filter: filterName, value };
            } else {
              return { value: block };
            }
          default:
            return { value: block };
        }

      }
    });
    this.splittedSearchBlocks = res;
  }

  public sortBots(listedBots: ChatbotConfig[]) {
    localStorage.setItem('adminSortingSelection', this.sortMode);
    listedBots.sort((a, b) => {
      const updateA = dayjs(a.updated).unix();
      const updateB = dayjs(b.updated).unix();
      const createA = dayjs(a.created).unix();
      const createB = dayjs(b.created).unix();
      switch (this.sortMode) {
        case "asc-alphabet":
          return a.name.localeCompare(b.name);
        case "desc-updated":
          if (updateA == updateB) return 0;
          if (updateA > updateB) return -1;
          return 1;
        case "desc-created":
          if (createA == createB) return 0;
          if (createA > createB) return -1;
          return 1;
      }
    });
    return listedBots;
  }
}
